import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {Router} from '@angular/router';
import {API} from "aws-amplify";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.less']
})
export class SidenavComponent implements OnInit {
  amplify: AmplifyService;
  lookerDashboardId = 67;

  constructor(amplify: AmplifyService,
              public router: Router) {
    this.amplify = amplify;
  }

  ngOnInit(): void {
  }

  logout(){
    this.amplify.auth().signOut().then(() => {
      this.router.navigate(['auth']);
    });
  }

  setLookerUrl(id: number) {
    this.lookerDashboardId = id;
  }
}
