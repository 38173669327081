<mat-sidenav-container class="example-container" autosize>
  <mat-sidenav mode="side" opened class="example-nav">
    <mat-icon svgIcon="Logo" aria-hidden="false" aria-label="Bytecode IO Icon" class="logo-icon"></mat-icon>
    <button mat-button class="dashboard-button" style="text-align: center;" (click)="setLookerUrl(67)">Ecommerce Dashboard</button>
    <button mat-button class="dashboard-button" (click)="setLookerUrl(70)">Ecommerce Dashboard With Filters</button>
    <button mat-button color="primary" class="dashboard-button" style="position: absolute; bottom: 10px;" (click)="logout()">Logout</button>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <app-looker class="looker" [lookerDashboardId]="lookerDashboardId"></app-looker>
  </mat-sidenav-content>
</mat-sidenav-container>
