import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component';
import {AuthComponent} from './auth/auth.component';
import {AccessGuard} from './guards/access-guard.service';
import {UnauthGuard} from './guards/unauth.guard';
import {HomeComponent} from './home/home.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { requiresLogin: true }, canActivate: [ AccessGuard ] },
  { path: 'auth', component: AuthComponent, canActivate: [ UnauthGuard ] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
