import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'connective-rx-mvp';


  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(`Logo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/BytecodeIO-logo-vector.svg'));

  }

  ngOnInit(): void {
  }
}
