/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a19612e6-ff9c-47b4-8fd6-a92b5f21b903",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3SpWuOzvM",
    "aws_user_pools_web_client_id": "2qo3jquopsslbrhv47l90bs801",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "getEmbedUrlApi",
            "endpoint": "https://6vmfs8ekc0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
