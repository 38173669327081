import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import Auth, { CognitoUser } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {
  // tslint:disable-next-line:variable-name
  constructor( private _router: Router ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
      // Only users with bytecode accounts can view the application.
      // @ts-ignore
      if (user.attributes.email.includes('@bytecode.io')) {
        return true;
      } else {
        Auth.signOut().then(() => (this._router.navigate(['unauthorized'])));
        return false;
      }
    })
      .catch(() => {
        this._router.navigate(['auth']);
        return false;
      });
  }
}
