import {Component, Input, OnChanges, OnInit, Pipe, PipeTransform, SimpleChanges} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { API } from 'aws-amplify';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-looker',
  templateUrl: './looker.component.html',
  styleUrls: ['./looker.component.less']
})
export class LookerComponent implements OnInit, OnChanges {
  lookerUrl = '';
  @Input() lookerDashboardId;

  constructor() {
    const apiName = 'getEmbedUrlApi';
    const path = `/looker/embed/67`;
    const init = {};
    API.get(apiName, path, init)
      .then(response => (this.lookerUrl = response.url))
      .catch(error => (console.log(error.response)));
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const apiName = 'getEmbedUrlApi';
    const path = `/looker/embed/${this.lookerDashboardId}`;
    const init = {};
    API.get(apiName, path, init)
      .then(response => (this.lookerUrl = response.url))
      .catch(error => (console.log(error.response)));
  }

}
