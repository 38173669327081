import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AmplifyUIAngularModule} from '@aws-amplify/ui-angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidenavComponent} from './sidenav/sidenav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {LookerComponent, SafePipe} from './looker/looker.component';
import {MatButtonModule} from '@angular/material/button';
import {HttpClientModule} from '@angular/common/http';
import {MatIconModule} from '@angular/material/icon';
import {AuthComponent} from './auth/auth.component';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import {AuthService} from './services/auth.service';
import {AmplifyService} from 'aws-amplify-angular';
import { HomeComponent } from './home/home.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

Amplify.configure(awsconfig)

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    LookerComponent,
    SafePipe,
    AuthComponent,
    HomeComponent,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatButtonModule,
    HttpClientModule,
    MatIconModule
  ],
  providers: [AmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule {
  // tslint:disable-next-line:variable-name
  constructor(_auth: AuthService) {}
}
